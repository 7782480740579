/* eslint-disable object-curly-newline */

// Node Modules
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Style
import { above, colors, fontSize, space } from '../../style/design-system';

const HeaderLeftSide = ({ className }) => (
  <div className={`${className}`}>
    <Link to="/" className="home-link">
      <StaticImage
        src="../../images/logos/cpb-logo.png"
        className="logo"
        alt="CarePaysBack.org logo"
        width={60}
        quality="100"
        layout="fixed"
        objectFit="contain"
        placeholder="none"
        loading="eager" />
      <span className="site-name">carepaysback.org</span>
    </Link>
  </div>
);

export default styled(HeaderLeftSide)`
  flex: 1 1 100%;
  padding-left: ${space.c};
  ${above.lg`
    padding-left: 0};
  `}
  .home-link {
    text-align: center;
    color: ${colors.umaBlack};
    text-decoration: none;
    display: flex;
    align-items: center;
    .logo {
      transform: scale(.8);
      ${above.lg`
      transform: scale(1);
      `}
    }
    .site-name {
      font-size: ${fontSize.g};
      font-weight: 600;
      margin-left: ${space.b};
      ${above.sm`
        font-size: ${fontSize.h};
        margin-left: ${space.c};
      `}
      ${above.md`
        font-size: ${fontSize.i};
      `}
      ${above.lg`
        font-size: ${fontSize.i}
      `}
      ${above.xl`
        font-size: ${fontSize.j}
      `}
    }
  }
  [class^="NavDesktop"] {
    align-self: stretch;
  }
`;

HeaderLeftSide.propTypes = {
  className: PropTypes.string.isRequired,
};
