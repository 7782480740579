// Node Modules
import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';

// Style
import { space } from '../../style/design-system';

// Utils
import { scrollToJoinUsForm } from '../../util/functions/scrollToJoinUsForm';

const JoinUsButton = ({ className }) => (
  <button className={`${className} d-none d-lg-block`} onClick={scrollToJoinUsForm} type="button">
    <StaticImage
      alt="Click button to scroll to form to join us."
      src="../../images/utils/join-us-btn.png"
      width={80} />
  </button>
);

export default styled(JoinUsButton)`
  background: transparent;
  border: none;
  outline: none;
  position: fixed;
  right: ${space.e};
  z-index: 1000;
  top: ${space.k};
`;

JoinUsButton.propTypes = {
  className: PropTypes.string.isRequired,
};
