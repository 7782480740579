// Node Modules
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Styles
import { above, colors, space } from '../../style/design-system';

const FooterGlobal = ({ className }) => (
  <div className={className}>
    <span>© 2021 CarePaysBack.org</span>
    <a href="https://www.ultimatemedical.edu/about/privacy-policy/" rel="noopener noreferrer" target="_blank">Privacy&nbsp;Policy</a>
  </div>
);

export default styled(FooterGlobal)`
  background-color: ${colors.umaLightGray};
  padding: ${space.g};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${above.sm`
    flex-direction: row;
    justify-content: space-between;
  `}
  span {
    flex: 0 0 75%;
    text-align: center;
    ${above.sm`
      text-align: left;
    `}
  }
  a {
    flex: 0 0 25%;
    color: ${colors.umaBlack};
    margin-top: ${space.d};
    ${above.sm`
      margin-top: 0;
      text-align: right;
    `}
  }
`;

FooterGlobal.propTypes = {
  className: PropTypes.string.isRequired,
};
