/* eslint-disable import/prefer-default-export */

export const lineHeight = {
  a: '0.00',
  b: '0.15',
  c: '0.30',
  d: '0.60',
  e: '1.0',
  f: '1.05',
  g: '1.10',
  h: '1.20',
  i: '1.5',
  j: '',
  k: '',
  l: '',
  m: '',
  n: '',
  o: '',
  p: '',
};
