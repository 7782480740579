// Node Modules
import { PropTypes } from 'prop-types';
import * as React from 'react';
import styled from 'styled-components';

// Components
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';

const HeaderGlobal = ({ className }) => (
  <header className={className}>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <HeaderMobile displayRange="d-lg-none" />
          <HeaderDesktop displayRange="d-none d-lg-flex" />
        </div>
      </div>
    </div>
  </header>
);

export default styled(HeaderGlobal)`
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 10;
`;

HeaderGlobal.propTypes = {
  className: PropTypes.string.isRequired,
};
