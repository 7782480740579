// Node Modules
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import styled from 'styled-components';

// Components
import NavMobile from '../navs/NavMobile';
import HeaderLeftSide from './HeaderLeftSide';

// Styles
import { fontSize, space } from '../../style/design-system';

const HeaderMobile = ({ className, displayRange }) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  useEffect(() => {
    const body = document.querySelector('body');
    // eslint-disable-next-line no-unused-expressions
    mobileNavOpen ? body.classList.add('prevent-scroll') : body.classList.remove('prevent-scroll');
  }, [mobileNavOpen]);

  const toggleMobileNavOpen = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  return (
    <div className={`${className} ${displayRange}`}>
      <div className="d-flex justify-content-space-between align-items-center">
        <HeaderLeftSide />

        {!mobileNavOpen && (
          <button type="button">
            <FaBars onClick={toggleMobileNavOpen} />
          </button>
        )}
        {mobileNavOpen && (
          <button type="button">
            <FaTimes onClick={toggleMobileNavOpen} />
          </button>
        )}
      </div>
      <NavMobile mobileNavOpen={mobileNavOpen} />
    </div>
  );
};

export default styled(HeaderMobile)`
  padding: ${space.c} 0;
  button {
    border: none;
    outline: none;
    background-color: #fff;
    font-size: ${fontSize.j};
    padding-right: ${space.d};
  }
`;

HeaderMobile.propTypes = {
  className: PropTypes.string.isRequired,
  displayRange: PropTypes.string.isRequired,
};
