// Node Modules
import * as React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

// Components
import FooterGlobal from '../footers/FooterGlobal';
import HeaderGlobal from '../headers/HeaderGlobal';
import GlobalStyle from '../../style/Global';
import JoinUsButton from '../resuables/JoinUsButton';

const Layout = ({ children, className, displayStickyButton }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className={className}>
      <HeaderGlobal siteTitle={data.site.siteMetadata?.title || 'Title'} />
      <div className="main-wrapper position-relative">
        {displayStickyButton && <JoinUsButton />}
        <main>{children}</main>
      </div>
      <FooterGlobal />
      <GlobalStyle />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  displayStickyButton: PropTypes.bool,
};

Layout.defaultProps = {
  className: undefined,
  displayStickyButton: true,
};

export default Layout;
