// Node Modules
import { createGlobalStyle } from 'styled-components';
import './bootstrap/bootstrap.scss';

// Styles
import {
  above, colors, lineHeight, space,
} from './design-system';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    font-size: 100%;
  }

  body {
    background-color: ${colors.cpb.almostWhite};
    color: ${colors.dev.gray.b()};
    font-family: system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
    font-size: 1rem;
    line-height: ${lineHeight.i};
    margin: 0;
    padding-top: 4.6875rem;
    ${above.md`
      font-size: 1.2rem;
    `}
    ${above.lg`
    padding-top: 5.0625rem;
    `}
    ${above.xl`
    padding-top: 5.25rem;
    `}
    ${above.xxl`
    padding-top: 5.4375rem;
    `}
  }

  body.modal-open, body.prevent-scroll {
    height: 100vh;
    overflow: hidden;
  }

  h1, .h1, 
  h2, .h2, 
  h3, .h3,
  h4, .h4, 
  h5, .h5, 
  h6, .h6 {
    letter-spacing: 0.6px;
    line-height: ${lineHeight.f}; //this equals 1.05 font size
    margin-bottom: 1rem;
    margin-top: 1.25rem;
  }

  .white { color: #fff;}
  .black { color: #000;}
  .blue {color: ${colors.dev.blue.d()};}
  .cpb-teal {color: ${colors.cpb.teal};}
  .cpb-yellow {color: ${colors.cpb.yellow};}
  .cpb-red {color: ${colors.cpb.red};}
  .cpb-purple {color: ${colors.cpb.purple};}
  .cpb-white {color: ${colors.cpb.white};}
  .cpb-bg-teal {background-color: ${colors.cpb.teal};}
  .cpb-bg-yellow {background-color: ${colors.cpb.yellow};}
  .cpb-bg-red {background-color: ${colors.cpb.red};}
  .cpb-bg-purple {background-color: ${colors.cpb.purple};}
  .cpb-bg-white {background-color: ${colors.cpb.white};}
  ${'' /* for development purposes only */}
  .red {
  color: red;
  font-weight: bold;
}

.margin-auto {
  margin: auto;
}

ul.greater-than-list {
  list-style: none;
  padding-left: 0;
  li {
    margin-bottom: ${space.b};
    text-indent: -2.125rem;
    margin-left: 1.125rem;
    &::before {
      content: ">";
      margin: 0.5rem 0.5rem 0px 1rem;
    }
  }
}

.o-h {
  overflow: hidden;
}

.jc-fs { justify-content: flex-start;}
.jc-fe { justify-content: flex-end;}
.jc-c { justify-content: center;}
.jc-sa { justify-content: space-around;}
.jc-sb { justify-content: space-between;}
.jc-se { justify-content: space-evenly;}

.ai-fs { align-items: flex-start;}
.ai-fe { align-items: flex-end;}
.ai-c { align-items: center;}
.ai-sa { align-items: space-around;}
.ai-sb { align-items: space-between;}
.ai-se { align-items: space-evenly;}
`;

export default GlobalStyle;
