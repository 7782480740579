/* eslint-disable sort-keys */

function hslaBuilder(hsl) {
  return (opacity = 1) => `hsla(${hsl.h}, ${hsl.s}%, ${hsl.l}%, ${opacity})`;
}

const colorDefinitions = {
  cpb: {
    almostWhite: '#FBFBFB',
    brown: '#834B00',
    gray: '#444',
    purple: '#802B59',
    red: '#F54000',
    silver: '#949999',
    teal: '#00C5BF',
    white: '#FFF7CF',
    yellow: '#FFDE17',
    yellowDarkened: '#F2D10A',
  },

  dev: {
    gray: {
      a: hslaBuilder({ h: 0, s: 0, l: 0 }),
      b: hslaBuilder({ h: 0, s: 0, l: 15 }),
      c: hslaBuilder({ h: 0, s: 0, l: 27 }),
      d: hslaBuilder({ h: 0, s: 0, l: 39 }),
      e: hslaBuilder({ h: 0, s: 0, l: 51 }),
      f: hslaBuilder({ h: 0, s: 0, l: 63 }),
      g: hslaBuilder({ h: 0, s: 0, l: 75 }),
      h: hslaBuilder({ h: 0, s: 3, l: 94 }),
      i: hslaBuilder({ h: 0, s: 0, l: 99 }),
    },
    blue: {
      a: hslaBuilder({ h: 196, s: 95, l: 9 }),
      b: hslaBuilder({ h: 202, s: 90, l: 18 }),
      c: hslaBuilder({ h: 202, s: 80, l: 28 }),
      d: hslaBuilder({ h: 201.3, s: 100, l: 32.5 }), // UMA Dark Blue
      e: hslaBuilder({ h: 196, s: 100, l: 39.8 }), // UMA Light Blue
      f: hslaBuilder({ h: 196, s: 75, l: 58 }),
      g: hslaBuilder({ h: 196, s: 85, l: 72 }),
      h: hslaBuilder({ h: 196, s: 100, l: 82 }),
      i: hslaBuilder({ h: 196, s: 100, l: 95 }),
    },
    green: {
      a: hslaBuilder({ h: 87, s: 75, l: 12 }),
      b: hslaBuilder({ h: 87, s: 75, l: 20 }),
      c: hslaBuilder({ h: 87, s: 75, l: 30 }),
      d: hslaBuilder({ h: 87, s: 70, l: 38 }), // UMA Green
      e: hslaBuilder({ h: 87, s: 70, l: 44 }), // UMA Lighter Green
      f: hslaBuilder({ h: 87, s: 65, l: 51 }),
      g: hslaBuilder({ h: 87, s: 75, l: 61 }),
      h: hslaBuilder({ h: 87, s: 85, l: 68 }),
      i: hslaBuilder({ h: 87, s: 98, l: 80 }),
    },
    orange: {
      a: hslaBuilder({ h: 23, s: 95, l: 10 }),
      b: hslaBuilder({ h: 23, s: 90, l: 18 }),
      c: hslaBuilder({ h: 25, s: 85, l: 30 }),
      d: hslaBuilder({ h: 27, s: 85, l: 42 }),
      e: hslaBuilder({ h: 29, s: 92, l: 57 }), // UMA Orange
      f: hslaBuilder({ h: 31, s: 85, l: 67 }),
      g: hslaBuilder({ h: 33, s: 85, l: 77 }),
      h: hslaBuilder({ h: 35, s: 90, l: 87 }),
      i: hslaBuilder({ h: 35, s: 96, l: 95 }),
    },
    purple: {
      a: hslaBuilder({ h: 268, s: 50, l: 15 }),
      b: hslaBuilder({ h: 268, s: 50, l: 24 }),
      c: hslaBuilder({ h: 268, s: 33, l: 33 }),
      d: hslaBuilder({ h: 268, s: 33, l: 42 }), // UMA Purple
      e: hslaBuilder({ h: 268, s: 33, l: 50 }),
      f: hslaBuilder({ h: 268, s: 40, l: 60 }),
      g: hslaBuilder({ h: 268, s: 55, l: 75 }),
      h: hslaBuilder({ h: 268, s: 95, l: 90 }),
      i: hslaBuilder({ h: 268, s: 95, l: 95 }),
    },
    yellow: {
      a: hslaBuilder({ h: 37, s: 95, l: 10 }),
      b: hslaBuilder({ h: 37, s: 80, l: 28 }),
      c: hslaBuilder({ h: 39, s: 75, l: 43 }),
      d: hslaBuilder({ h: 41, s: 75, l: 55 }),
      e: hslaBuilder({ h: 43, s: 95, l: 63 }), // UMA Yellow
      f: hslaBuilder({ h: 43, s: 95, l: 75 }),
      g: hslaBuilder({ h: 47, s: 90, l: 80 }),
      h: hslaBuilder({ h: 49, s: 90, l: 90 }),
      i: hslaBuilder({ h: 49, s: 95, l: 95 }),
    },
    pink: {
      a: hslaBuilder({ h: 336, s: 95, l: 18 }),
      b: hslaBuilder({ h: 336, s: 85, l: 28 }),
      c: hslaBuilder({ h: 336, s: 80, l: 38 }),
      d: hslaBuilder({ h: 336, s: 70, l: 48 }),
      e: hslaBuilder({ h: 303, s: 70, l: 44 }), // UMA Pink
      f: hslaBuilder({ h: 336, s: 75, l: 68 }),
      g: hslaBuilder({ h: 336, s: 80, l: 78 }),
      h: hslaBuilder({ h: 336, s: 85, l: 88 }),
      i: hslaBuilder({ h: 336, s: 95, l: 95 }),
    },
    red: {
      a: hslaBuilder({ h: 346, s: 80, l: 10 }),
      b: hslaBuilder({ h: 346, s: 75, l: 20 }),
      c: hslaBuilder({ h: 346, s: 70, l: 30 }),
      d: hslaBuilder({ h: 348, s: 80, l: 35 }),
      e: hslaBuilder({ h: 348, s: 88, l: 39 }), // UMA Red
      f: hslaBuilder({ h: 348, s: 95, l: 45 }),
      g: hslaBuilder({ h: 350, s: 88, l: 55 }),
      h: hslaBuilder({ h: 350, s: 90, l: 65 }),
      i: hslaBuilder({ h: 350, s: 95, l: 95 }),
    },
    teal: {
      a: hslaBuilder({ h: 189, s: 90, l: 15 }),
      b: hslaBuilder({ h: 189, s: 90, l: 20 }),
      c: hslaBuilder({ h: 187, s: 80, l: 25 }),
      d: hslaBuilder({ h: 185, s: 80, l: 29 }),
      e: hslaBuilder({ h: 183, s: 100, l: 34 }), // UMA Teal
      f: hslaBuilder({ h: 181, s: 85, l: 43 }),
      g: hslaBuilder({ h: 179, s: 70, l: 55 }),
      h: hslaBuilder({ h: 177, s: 80, l: 65 }),
      i: hslaBuilder({ h: 177, s: 90, l: 78 }),
    },
  },

  social: {
    facebook: '#3B5998',
    gitHub: {
      hoverGreenBase: '#269F42',
      hoverGreenLight: '#2FCB53',
    },
    instagram: '#F5005D',
    linkedIn: '#0A66C2',
    pinterest: '#E60023',
    twitter: '#1DA1F2',
    youTube: '#F50804',
  },

};

const colorNames = {
  blueDarkPastel: '#132027',
  umaBlack: colorDefinitions.dev.gray.b(),
  umaDarkBlue: colorDefinitions.dev.blue.d(),
  umaGreen: colorDefinitions.dev.green.e(),
  umaDarkGreen: colorDefinitions.dev.green.d(),
  umaLightBlue: colorDefinitions.dev.blue.e(),
  umaOrange: colorDefinitions.dev.orange.e(),
  umaPink: colorDefinitions.dev.pink.e(),
  umaPurple: colorDefinitions.dev.purple.e(),
  umaRed: colorDefinitions.dev.red.e(),
  umaTeal: colorDefinitions.dev.teal.d(),
  umaYellow: colorDefinitions.dev.yellow.e(),
  umaUltimateLightGray: colorDefinitions.dev.gray.i(),
  umaLightGray: colorDefinitions.dev.gray.h(),
  umaCoolGray: colorDefinitions.dev.gray.e(),
  umaColdGray: colorDefinitions.dev.gray.d(),
  umaDarkGray: colorDefinitions.dev.gray.c(),
};

// eslint-disable-next-line import/prefer-default-export
export const colors = Object.assign(colorDefinitions, colorNames);
