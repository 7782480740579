// Node Modules
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Components
import NavDesktop from '../navs/NavDesktop';
import HeaderLeftSide from './HeaderLeftSide';

// Styles
import { above, space } from '../../style/design-system';

const HeaderDesktop = ({ className, displayRange }) => (
  <div className={`${className} ${displayRange} justify-content-space-between align-items-center`}>
    <HeaderLeftSide />
    <NavDesktop />
  </div>
);

export default styled(HeaderDesktop)`
  background-color: #fff;
  padding: 0 ${space.e};
  ${above.xl`
    padding: 0 ${space.f};
  `}
  ${above.xxl`
    padding: 0 ${space.g};
  `}
  [class^="NavDesktop"] {
    align-self: stretch;
  }
`;

HeaderDesktop.propTypes = {
  className: PropTypes.string.isRequired,
  displayRange: PropTypes.string.isRequired,
};
