/* eslint-disable object-curly-newline */

// Node Modules
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';
import styled from 'styled-components';

// Styles
import { above, colors, fontSize, space } from '../../style/design-system';

const NavDesktop = ({ className }) => (
  <nav className={`${className} d-flex`}>
    <Link activeClassName="active" className="nav-header-link" to="/why-we-care/">
      <div className="color-block cpb-bg-teal" />
      <div className="link-text">Why We Care</div>
    </Link>
    <Link activeClassName="active" className="nav-header-link" to="/resources/">
      <div className="color-block cpb-bg-yellow" />
      <div className="link-text">Resources</div>
    </Link>
    <Link activeClassName="active" className="nav-header-link" to="/articles/">
      <div className="color-block cpb-bg-red" />
      <div className="link-text">Articles</div>
    </Link>
    <Link activeClassName="active" className="nav-header-link" to="/courses/">
      <div className="color-block cpb-bg-purple" />
      <div className="link-text">Courses</div>
    </Link>
    <Link activeClassName="active" className="nav-header-link" to="/our-network/">
      <div className="color-block cpb-bg-white" />
      <div className="link-text">Our Network</div>
    </Link>
  </nav>
);

export default styled(NavDesktop)`
  justify-content: flex-end;
  .nav-header-link {
    display: flex;
    flex-direction: column;
    flex: 0 0 7.5rem;
    font-size: ${fontSize.d};
    text-decoration: none;
    text-transform: uppercase;
    &.active {
      font-weight: 900;
    }
    &:hover {
      font-weight: bold;
      text-decoration: none;
    }
    ${above.xl`
      flex: 0 0 8rem;
      font-size: ${fontSize.e};
    `}
    ${above.xxl`
      flex: 0 0 10rem;
      font-size: ${fontSize.f};
    `}
    .color-block {
      height: .75rem;
    }
    .link-text {
      color: ${colors.umaBlack};
      padding: ${space.f} 0;
      text-align: center;
    }
  }
`;

NavDesktop.propTypes = {
  className: PropTypes.string.isRequired,
};
