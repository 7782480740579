// Node Modules
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

//  Style
import { colors, space } from '../../style/design-system';

const NavMobile = ({ className, mobileNavOpen }) => (
  <nav className={className}>

    <div className={`links container-fluid ${mobileNavOpen ? 'open' : ''}`}>
      <div className="links-internal nowrap">
        <Link activeClassName="active" className="nav-mobile-link" to="/why-we-care/">
          <div className="color-block cpb-bg-teal" />
          <div className="link-text">Why We Care</div>
        </Link>
        <Link activeClassName="active" className="nav-mobile-link" to="/resources/">
          <div className="color-block cpb-bg-yellow" />
          <div className="link-text">Resources</div>
        </Link>
        <Link activeClassName="active" className="nav-mobile-link" to="/articles/">
          <div className="color-block cpb-bg-red" />
          <div className="link-text">Articles</div>
        </Link>
        <Link activeClassName="active" className="nav-mobile-link" to="/courses/">
          <div className="color-block cpb-bg-purple" />
          <div className="link-text">Courses</div>
        </Link>
        <Link activeClassName="active" className="nav-mobile-link" to="/our-network/">
          <div className="color-block cpb-bg-white" />
          <div className="link-text">Our Network</div>
        </Link>
      </div>
    </div>
  </nav>
);

export default styled(NavMobile)`
  .links {
    position: absolute;
    left: 0;
    top: 75px;
    height: 100vh;
    overflow: hidden;
    transition: all .3s ease;
    background: ${colors.dev.gray.h()};
    width: 0;
    padding: 0;
    z-index: 10;
    &.open {
      width: 100vw;
    }
    .links-internal {
      .nav-mobile-link {
        align-items: center;
        color: ${colors.dev.gray.a()};
        display: flex;
        text-transform: uppercase;
        text-decoration: none;
        height: 4rem;
        &:hover {
          font-weight: bold;
          text-decoration: none;
        }
        &.active {
          font-weight: 900;
        }
        .color-block {
          width: 4rem;
          align-self: stretch;
        }
        .link-text {
          padding-left: ${space.f};
        }
      }
    }
  }
`;

NavMobile.propTypes = {
  className: PropTypes.string.isRequired,
  mobileNavOpen: PropTypes.bool,
};

NavMobile.defaultProps = {
  mobileNavOpen: false,
};
