/* eslint-disable import/prefer-default-export */

export const scrollToJoinUsForm = () => {
  const joinUsForm = document.getElementById('join-us-form-wrapper');
  window.scrollTo({
    behavior: 'smooth',
    left: 0,
    top: joinUsForm.offsetTop - 50,
  });
};
